import countUp from "@/views/my-components/widget/countUp.vue";
export default {
  name: "card-area",
  components: {
    countUp
  },
  props: {
    id: {
      type: String,
      default: "countup"
    },
    title: {
      type: String,
      default: "-"
    },
    value: {
      type: Number,
      default: 0
    },
    decimalPlaces: {
      type: Number,
      default: 0
    },
    prefix: String,
    suffix: String,
    status: {
      type: String,
      default: "up"
    },
    compare: String,
    since: {
      type: String,
      default: "与上周相比"
    }
  },
  data() {
    return {
      series: [{
        data: []
      }],
      chartOptions: {
        colors: ["#34c38f"],
        chart: {
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        fill: {
          opacity: 0.3
        },
        xaxis: {
          crosshairs: {
            width: 1
          }
        },
        yaxis: {
          min: 0,
          max: 8
        },
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              }
            }
          },
          marker: {
            show: false
          }
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        data: [2, 3, 4, 3, 5, 4, 5, 6, 5]
      }];
    }
  },
  mounted() {
    this.init();
  }
};